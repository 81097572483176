import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import debounce from 'lodash/debounce'
import { PersistGate } from 'redux-persist/integration/react'
import Modal from 'react-modal'

import LaunchDarklyContextProvider from 'context/launch_darkly'
import { updateWindowSize, updateDeviceInfo } from 'dux/device'
import {
  getDeviceWidth,
  getDeviceHeight,
  getLanguage,
} from 'utils/browser/device'
import Login from 'components/login'
import AuthenticatedRoute from 'containers/authentication'
import PendoController from 'containers/pendo_controller'
import DatadogRUM from 'containers/datadog_rum'
import Structure from 'containers/structure_app'

Modal.setAppElement('#root')

function App({ store, persistor }) {
  useEffect(() => {
    const recordDeviceInfo = () => {
      const userAgentString = navigator.userAgent
      const language = getLanguage()
      store.dispatch(updateDeviceInfo(userAgentString, language))
    }
    const recordWindowSize = debounce(() => {
      const width = getDeviceWidth()
      const height = getDeviceHeight()
      store.dispatch(updateWindowSize(width, height))
    }, 200)

    recordDeviceInfo(store)
    recordWindowSize()
    window.addEventListener('resize', recordWindowSize)

    return () => {
      window.removeEventListener('resize', recordWindowSize)
    }
  }, [store])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LaunchDarklyContextProvider>
          <DatadogRUM />
          <PendoController />
          <BrowserRouter>
            <Switch>
              <AuthenticatedRoute path='/logout' component={Login} />
              <AuthenticatedRoute path='/login' component={Login} />
              <AuthenticatedRoute path='/' component={Structure} />
            </Switch>
          </BrowserRouter>
        </LaunchDarklyContextProvider>
      </PersistGate>
    </Provider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
}

export default App
