import { createSelector } from 'reselect'
import pickBy from 'lodash/pickBy'
import values from 'lodash/values'
import { createEntityGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import { selectActiveGroups } from 'selectors/group_types'
import {
  selectHasOrganizationResultsReadPermission,
  selectUserPermissions
} from 'selectors/user'
import { ACCESSIBLE_TYPES } from 'constants/permissions'

const selectV3GroupPermissions = createSelector(
  selectUserPermissions,
  permissions => {
    const filtered = {}
    Object.keys(permissions).forEach(k => {
      if (permissions[k].accessibleType === ACCESSIBLE_TYPES.V3_GROUPS) {
        filtered[k] = permissions[k]
      }
    })
    return filtered
  }
)

export const selectPermittedV3Groups = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  selectHasOrganizationResultsReadPermission,
  selectV3GroupPermissions,
  (groups, hasOrgReadPermission, permissions) => {
    const permissionsArray = values(permissions)
    return pickBy(groups, group => {
      if (hasOrgReadPermission) {
        return true
      }
      // Check for the specific permissions for this group
      return (
        permissionsArray.find(p => `${p.accessibleId}` === group.id) !==
        undefined
      )
    })
  }
)
