import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import {
  selectOrganization,
  selectPermittedV3GroupIds,
  selectPerson,
  selectUsername,
  selectResultAccessLevel,
  selectUserRole
} from 'selectors/user'
import { selectOrganizationPackageProgress } from 'selectors/packages'

const { APP_VERSION } = process.env

/**
 * Get the session_id from sessionStorage or refresh if it has been over 30 minutes since
 * the last event was fired
 */
function getSessionId() {
  const timestamp = +new Date()
  const lastEvent = sessionStorage.getItem('lastAnalyticsEvent')
  const threshold = dayjs(new Date(lastEvent)).add(30, 'm')
  if (Date.now() > threshold) {
    sessionStorage.setItem('analyticsSessionId', timestamp)
  }
  sessionStorage.setItem('lastAnalyticsEvent', timestamp)
  return sessionStorage.getItem('analyticsSessionId')
}

function getDeviceId() {
  let deviceId = localStorage.getItem('deviceId')
  if (!deviceId) {
    deviceId = uuidv4()
    localStorage.setItem('deviceId', deviceId)
  }

  return deviceId
}

/**
 * Gathers relevant system properties to be shipped along with user information
 * Keys are in snake case to support Amplitude
 *
 * @param {Object} device - The redux store.device node
 * @returns {Object} - The parsed user agent data stored in the state tree
 */
function getUserAgentProperties(device) {
  return {
    platform: device.platform,
    device_type: device.device_type,
    device_model: device.device_model,
    carrier: device.carrier,
    app_version: APP_VERSION,
    os_name: device.os_name,
    os_version: device.os_version,
    language: device.language,
    start_version: device.start_version
  }
}

/**
 * Returns an object containing custom user properties that we want to track within Full Story.
 *
 * @param {Object} state - The entire Redux state tree
 * @returns {Object} - properties associated with the current user
 */
function getFullStoryProperties(state) {
  const person = selectPerson(state)
  const organization = selectOrganization(state)
  const displayName = selectUsername(state)
  const groupCount = selectPermittedV3GroupIds(state).length || null
  const role = selectUserRole(state)
  const resultsAccess = selectResultAccessLevel(state)

  return {
    displayName,
    email: get(person, 'emailAddress'),
    organizationName: get(organization, 'name'),
    role,
    resultsAccess,
    groupCount
  }
}

export {
  getSessionId,
  getDeviceId,
  getUserAgentProperties,
  getFullStoryProperties
}
