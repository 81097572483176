import {
  isApiCall,
  CLEAR_ENTITIES,
  CLEAR_ENTITY_BY_ID,
  CLEAR_PERSON_PERMISSIONS,
  ADJUST_EMPLOYEE_MEMBERSHIP_COUNT,
  statuses,
  methods,
} from 'dux/api/action_types'
import filter from 'lodash/filter'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { SWITCH_ORGANIZATION } from 'dux/organizations'

const initState = {
  'ai-open-response-themes': {},
  'ai-open-response-summaries': {},
  actions: {},
  'action-occurrences': {},
  'action-resources': {},
  'action-plans': {},
  'action-plan-driver-changes': {},
  'average-scores': {},
  'assessment-statement-results': {},
  'campaign-bracket-movements': {},
  'campaign-category-scores': {},
  'campaign-categories': {},
  'campaign-filters': {},
  'campaign-memberships': {},
  'campaign-notifications': {},
  'campaign-participations': {},
  'campaign-participants': {},
  'campaign-results': {},
  'campaign-statements': {},
  'campaign-surveys': {},
  'campaign-targets': {},
  campaigns: {},
  categories: {},
  'category-scores-correlations': {},
  changes: {},
  'coaching-sessions': {},
  'coaching-session-types': {},
  'coaching-subscription-transform-resource-deadlines': {},
  'compiled-message-partials': {},
  'compiled-message-templates': {},
  'custom-campaigns': {},
  'emplify-score-bracket-movement': {},
  'emplify-score-brackets': {},
  'emplify-score-summaries': {},
  'employee-attributes': {},
  'employee-management-items': {},
  'employee-management-items-bulk': {},
  'employee-results': {},
  'employee-result-category-scores': {},
  employees: {},
  'enps-statement-results': {},
  'explicit-permissions': {},
  'facilitation-teams': {},
  'ff-group-types': {},
  'group-benchmarks': {},
  'group-category-scores': {},
  'group-emplify-brackets': {},
  'group-participations': {},
  'group-results': {},
  'group-types': {},
  'groups-types': {},
  groups: {},
  'hire-cohort-groups': {},
  'internal-people': {},
  items: {},
  jobs: {},
  'job-types': {},
  'leader-group-associations': {},
  'lifecycle-campaigns': {},
  locales: {},
  memberships: {},
  messages: {},
  'open-responses': {},
  options: {},
  'optimized-organizations': {},
  'optimized-employees': {},
  'optimized-v3-groups': {},
  'option-sets': {},
  'organization-benchmarks': {},
  'organization-employee-attributes': {},
  'organization-facilitation-teams': {},
  'organization-facilitators': {},
  'organization-files-download': {},
  'organization-files': {},
  'organization-group-types': {},
  'organization-locales': {},
  'organization-package-progress': {},
  'organization-statements': {},
  'organization-substitutions': {},
  organizations: {},
  'parsed-survey': {},
  people: {},
  permissions: {},
  'person-roles': {},
  'product-features': {},
  prompts: {},
  'query-campaign-themes': {},
  roles: {},
  'segment-filters': {},
  'segments': {},
  sentiment: {},
  'short-url': {},
  statements: {},
  'substitution-types': {},
  substitutions: {},
  'survey-prompts': {},
  'survey-statements': {},
  'survey-types': {},
  surveys: {},
  'tenure-groups': {},
  'text-analysis': {},
  'transform-users': {},
  'transform-paths': {},
  'transform-resources': {},
  'unique-employee-attributes': {},
  'v2-employee-previews': {},
  'v3-campaign-memberships': {},
  'v3-groups': {},
  'v3-group-emplify-brackets': {},
  'v3-group-participations': {},
  'v3-group-results': {},
  'v3-memberships': {},
}

/**
 * Return a new state tree with updated and new entities applied
 */
function merge(state, receivedEntities) {
  if (!receivedEntities) {
    return state
  }

  const entityKeys = Object.keys(receivedEntities)
  if (!entityKeys || entityKeys.length < 1) {
    return state
  }

  const newState = {}

  entityKeys.forEach((entityKey) => {
    const newEntityState = { ...(state[entityKey] || {}) }
    const receivedIds = Object.keys(receivedEntities[entityKey])

    /*
     * For each entity that we receive, we check if it was already in the state tree
     * If it is, merge the old and new properties together.
     * This is a bit of a hacky fix to a problem we have with relationships.
     * Currently, we use foreign keys as properties (e.g., employee.personId).
     * However, if we ?include the related entities in the payload, then we have actual
     * JSON API relationship objects
     * e.g., employee.person = { id: '55', type: 'people' }.
     *
     * If we already have an entity in state and it has relationship objects on it, if we PATCH
     * that entity, or if we GET it again without the ?include, the response will not have the
     * relationship property on the entity. So we spread in the old state to
     * keep relationships properties around.
     *
     * This problem would go away if we always used relationship objects instead of foreign keys.
     */
    receivedIds.forEach((receivedId) => {
      newEntityState[receivedId] = {
        ...((state[entityKey] && state[entityKey][receivedId]) || {}),
        ...receivedEntities[entityKey][receivedId],
      }
    })

    newState[entityKey] = newEntityState
  })

  return {
    ...state,
    ...newState,
  }
}

// Individual reducers
function receiveEntities(state = initState, action) {
  return merge(state, action.data.entities)
}

/**
 * Adds the ability to filter out a related entity by a foreignKey and foreignId.
 * Which allows us to remove related entities based upon a criteria provided by the deletion async action.
 * Use this in a "hasMany" relationship (A campaigns hasMany events)
 * Upon campaign delete -> clear the events
 * @param {Object} relatedForeignEntities
 * @param {String} relatedForeignEntities.entity Type of Entity to remove ("campaigns" as an example)
 * @param {String} relatedForeignEntities.foreignKey Object key to check the foreignId against
 * @param {String|number} relatedForeignEntities.foreignId
 * @param {Object} relatedForeignEntities.include Include additional entities for the relatedForeignEntities
 */
function clearRelatedForeignEntities(state, relatedForeignEntities) {
  const newState = {
    ...state,
  }
  const foreignEntities = Array.isArray(relatedForeignEntities)
    ? relatedForeignEntities
    : [relatedForeignEntities]

  foreignEntities.forEach((foreignEntity) => {
    const { entity, foreignKey, foreignId } = foreignEntity

    if (foreignEntity.include) {
      const include = Array.isArray(foreignEntity.include)
        ? foreignEntity.include
        : [foreignEntity.include]

      const entities = filter(newState[entity], (item) => {
        return `${item[foreignKey]}` === `${foreignId}`
      })

      entities.forEach((e) => {
        include.forEach((i) => {
          newState[i.entity] = keyBy(
            filter(newState[i.entity], (item) => {
              return `${item[i.foreignKey]}` !== `${e.id}`
            }),
            'id',
          )
        })
      })
    }
    newState[entity] = keyBy(
      filter(newState[entity], (item) => {
        return `${item[foreignKey]}` !== `${foreignId}`
      }),
      'id',
    )
  })

  return newState
}

function reduceUpdate(state = initState, action) {
  let newState = {
    ...state,
  }

  if (action.clearRelatedForeignEntities) {
    newState = clearRelatedForeignEntities(
      newState,
      action.clearRelatedForeignEntities,
    )
  }

  return receiveEntities(newState, action)
}

function reduceDelete(state = initState, action) {
  const entity = action.entity
  const deletedId = action.id
  let newState = {
    ...state,
    [entity]: {
      // We will delete an object from here, so get a new reference
      ...state[entity],
    },
  }
  delete newState[entity][deletedId]

  /**
   * Adds the ability to filter out a related entity by a foreignKey and foreignId.
   * Which allows us to remove related entities based upon a criteria provided by the deletion async action.
   * Use this in a "hasMany" relationship (A campaigns hasMany events)
   * Upon campaign delete -> clear the events
   * @param {Object} clearRelatedForeignEntities
   * @param {String} clearRelatedForeignEntities.entity Type of Entity to remove ("campaigns" as an example)
   * @param {String} clearRelatedForeignEntities.foreignKey Object key to check the foreignId against
   * @param {String|number} clearRelatedForeignEntities.foreignId
   */
  if (action.clearRelatedForeignEntities) {
    newState = clearRelatedForeignEntities(
      newState,
      action.clearRelatedForeignEntities,
    )
  }

  return newState
}

function reduceClearEntities(state = initState, action) {
  const newState = { ...state }
  action.entities.forEach((e) => {
    newState[e] = {}
  })
  return newState
}

function reduceClearEntityById(state = initState, action) {
  const newState = { ...state }
  delete newState[action.entity][action.entityId]
  return newState
}

// On the Employee Edit page, we pull an employee's person permissions into state
// to populate their permitted groups. This function is used to clear those stale
// permissions when unmounting the Employee Edit page
function reduceClearPersonPermissions(state = initState, action) {
  const personId = action.personId
  if (!personId) {
    return state
  }

  let permissionsDeleted = false
  const newPermissions = { ...state.permissions }
  Object.entries(state.permissions).forEach(([id, permission]) => {
    if (`${permission.personId}` === `${personId}`) {
      delete newPermissions[id]
      permissionsDeleted = true
    }
  })

  if (!permissionsDeleted) {
    return state
  }

  return {
    ...state,
    permissions: newPermissions,
  }
}

function switchOrganization(state = initState) {
  const action = {
    entities: [
      'action-plans',
      'average-scores',
      'assessment-statement-results',
      'campaign-bracket-movements',
      'campaign-category-scores',
      'campaign-categories',
      'campaign-memberships',
      'campaigns',
      'campaign-results',
      'campaign-surveys',
      'campaign-targets',
      'categories',
      'custom-campaigns',
      'category-scores-correlations',
      'coaching-sessions',
      'coaching-subscriptions',
      'coaching-subscription-transform-resource-deadlines',
      'emplify-score-bracket-movement',
      'employee-attributes',
      'employee-management-items',
      'enps-statement-results',
      'ff-group-types',
      'group-category-scores',
      'group-emplify-brackets',
      'group-participations',
      'group-results',
      'group-types',
      'groups',
      'hire-cohort-groups',
      'jobs',
      'memberships',
      'optimized-employees',
      'optimized-v3-groups',
      'organization-benchmarks',
      'organization-employee-attributes',
      'organization-facilitation-teams',
      'organization-facilitators',
      'organization-group-types',
      'open-responses',
      'prompts',
      'segment-filters',
      'segments',
      'statements',
      'survey-prompts',
      'survey-statements',
      'surveys',
      'tenure-groups',
      'transform-users',
      'unique-employee-attributes',
      'v2-employee-previews',
      'v3-campaign-memberships',
      'v3-group-emplify-brackets',
      'v3-group-participations',
      'v3-group-results',
      'v3-memberships',
    ],
  }

  // In order to reduce fetching, we're maintaining all of the org root groups in entities when swapping orgs
  // This should only affect admins and people who have access to more than one organization
  const newState = reduceClearEntities(state, action)
  const groupsToMaintain = keyBy(
    filter(newState['v3-groups'], (v3g) => {
      return v3g.isOrganizationGroup
    }),
    'id',
  )

  newState['v3-groups'] = groupsToMaintain
  return newState
}

function reduceAdjustEmployeeMembershipCount(state, action = {}) {
  const { amount, employeeId } = action
  if (!amount || !employeeId) {
    return state
  }

  const currentCount = get(state, `employees.${employeeId}.membershipCount`)
  if (currentCount === null || currentCount === undefined) {
    return state
  }

  const newEmployee = {
    ...state.employees[employeeId],
    membershipCount: currentCount + amount,
  }

  return {
    ...state,
    employees: {
      ...state.employees,
      [employeeId]: newEmployee,
    },
  }
}

/**
 * Clear ALL entity values for each named entity in the entitiesToClear array
 * This action should be used very sparingly
 * The preferred method of removing an entity is send a DELETE request
 * @param {Array} entitiesToClear An array of strings - the names of each entity to fully clear
 */
export function clearEntities(entitiesToClear) {
  return {
    type: CLEAR_ENTITIES,
    entities: entitiesToClear,
  }
}

export function clearEntityById(entity, entityId) {
  return {
    type: CLEAR_ENTITY_BY_ID,
    entity,
    entityId,
  }
}

// Entity reducer
export default function reducer(state = initState, action) {
  if (!action || !action.type) {
    return state
  }

  if (action.type === CLEAR_ENTITIES) {
    return reduceClearEntities(state, action)
  }

  if (action.type === CLEAR_ENTITY_BY_ID) {
    return reduceClearEntityById(state, action)
  }

  if (action.type === CLEAR_PERSON_PERMISSIONS) {
    return reduceClearPersonPermissions(state, action)
  }

  if (action.type === SWITCH_ORGANIZATION) {
    return switchOrganization(state)
  }

  if (action.type === ADJUST_EMPLOYEE_MEMBERSHIP_COUNT) {
    return reduceAdjustEmployeeMembershipCount(state, action)
  }

  if (!isApiCall(action.type)) {
    return state
  }

  if (action.status !== statuses.SUCCESS) {
    return state
  }

  if (action.method === methods.GET || action.method === methods.POST) {
    if (!action.data) {
      return state
    }

    return receiveEntities(state, action)
  }

  if (action.method === methods.PATCH) {
    if (!action.data) {
      return state
    }

    return reduceUpdate(state, action)
  }

  if (action.method === methods.DELETE) {
    return reduceDelete(state, action)
  }

  return state
}
