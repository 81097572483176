import 'core-js/stable'

import React from 'react'
import { render } from 'react-dom'
// ESLint doesn't know that we are importing this dev dependency for a valid dev reason
// eslint-disable-next-line import/no-extraneous-dependencies
import { AppContainer } from 'react-hot-loader'
import { configureAppStore } from 'dux'
import App from 'app'
import 'styles/import.scss'

const { store, persistor } = configureAppStore()

render(
  <AppContainer>
    <App store={store} persistor={persistor} />
  </AppContainer>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept('./app', () => {
    // eslint-disable-next-line global-require
    const HotApp = require('./app').default
    render(
      <AppContainer>
        <HotApp store={store} persistor={persistor} />
      </AppContainer>,
      document.getElementById('root'),
    )
  })
}

// set current git hash so we know what version of the code is running
window.CURRENT_COMMIT_SHA = process.env.CIRCLE_SHA1
