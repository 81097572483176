import get from 'lodash/get'
import pick from 'lodash/pick'
import includes from 'lodash/includes'
import {
  isApiCall,
  statuses,
  methods,
  CLEAR_ENTITIES,
} from 'dux/api/action_types'
import { SWITCH_ORGANIZATION } from 'dux/organizations'
import { serializeRequestParams } from '../helper'

function generateNewParamsRequestState(method, previousState, action) {
  const newMethodParamsState = {
    ...get(previousState, [action.entity, method], {}),
  }

  const params = get(action, 'params')
  // NOTE: Don't care for includes right now for request phase tracking
  const queryOptions = { ...get(action, 'query') }
  if (queryOptions) {
    delete queryOptions.include
  }
  const combinedParams = { ...params, ...queryOptions }
  const paramKey = serializeRequestParams(combinedParams)

  newMethodParamsState[paramKey] = action.status

  return newMethodParamsState
}

// TODO: Use our entities constant to define each key for request state
const initState = {
  'ai-open-response-themes': {
    GET: {},
    POST: {},
  },
  'ai-open-response-summaries': {
    GET: {},
    POST: {},
  },
  actions: { GET: {} },
  'action-occurrences': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'action-plans': { GET: {}, POST: {}, PATCH: {}, DELETE: {} },
  'action-plan-driver-changes': { GET: {} },
  'assessment-statement-results': { GET: {} },
  brackets: { GET: {} },
  'coaching-sessions': { GET: {}, POST: {}, PATCH: {} },
  'customer-success': { GET: {} },
  'optimized-employees': { GET: {} },
  employees: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  organizations: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'optimized-organizations': { GET: {} },
  campaigns: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'campaign-results': {
    GET: {},
    isFetching: false,
  },
  'campaign-insights': {
    DELETE: {},
    deleting: {},
  },
  'enps-statement-results': { GET: {} },
  results: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  jobs: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'job-types': {
    GET: {},
  },
  employments: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'employee-csv': {
    isFetching: false,
  },
  'group-types': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  surveys: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  statements: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'survey-prompts': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'survey-statements': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  people: {
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'person-roles': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  memberships: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  groups: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'generation-groups': {
    GET: {},
  },
  'tenure-groups': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'hire-cohort-groups': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    updating: {},
    deleting: {},
  },
  'organization-files': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    isCreating: false,
    deleting: {},
  },
  'organization-files-download': {
    GET: {},
  },
  'organization-files-upload': {
    POST: {},
  },
  permissions: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
    deleting: {},
  },
  'group-category-scores': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetchingByCampaign: {},
  },
  categories: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
  },
  'cached-category-scores': {
    deleting: {},
  },
  'option-sets': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
  },
  'bracket-movements': {
    GET: {},
    isFetching: false,
  },
  'emplify-score-brackets': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'group-emplify-brackets': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'generation-emplify-scores': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetchingByCampaign: {},
  },
  'organization-locales': {
    GET: {},
    POST: {},
    DELETE: {},
    isFetching: false,
  },
  'group-results': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
  },
  'campaign-category-scores': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetchingByCampaign: {},
  },
  'campaign-categories': {
    GET: {},
  },
  'campaign-participations': {
    GET: {},
  },
  'group-participations': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
  },
  'category-scores-correlations': {
    GET: {},
    isFetchingByCampaign: {},
  },
  'emplify-score-summaries': {
    GET: {},
    isFetching: false,
  },
  'smart-pulse': {
    isFetching: false,
  },
  'survey-types': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetching: false,
  },
  'non-respondents-csv': {
    isFetching: false,
  },
  responses: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
    isFetchingByCampaign: {},
  },
  cache: {
    GET: {},
  },
  'message-statuses': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  messages: {
    GET: {},
  },
  items: {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'organization-group-types': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'campaign-memberships': {
    GET: {},
    isFetching: false,
  },
  'organization-substitutions': {
    GET: {},
    POST: {},
    PATCH: {},
  },
  substitutions: {
    GET: {},
  },
  'groups-types': {
    GET: {},
  },
  'campaign-notifications': {
    GET: {},
    deleting: {},
  },
  'compiled-message-templates': {
    GET: {},
  },
  'compiled-message-partials': {
    GET: {},
  },
  'executive-advisors': {
    GET: {},
  },
  'organization-overview-pdf': {
    GET: {},
  },
  'leader-group-associations': {
    GET: {},
    POST: {},
    PATCH: {},
  },
  'v3-campaign-memberships': {
    GET: {},
  },
  'v3-category-scores': { GET: {} },
  'optimized-v3-groups': { GET: {} },
  'v3-groups': {
    GET: {},
  },
  'v3-group-emplify-brackets': {
    GET: {},
  },
  'v3-group-participations': {
    GET: {},
  },
  'v3-group-results': {
    GET: {},
  },
  'v3-memberships': {
    GET: {},
  },
  'organization-employee-attributes': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'employee-attributes': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'group-benchmarks': {
    GET: {},
  },
  'average-scores': {
    GET: {},
  },
  'feedback-responses': {
    GET: {},
  },
  'open-responses': {
    GET: {},
  },
  'text-analysis': {
    GET: {},
  },
  sentiment: {
    GET: {},
  },
  bookmarks: {
    GET: {},
  },
  'employee-results': {
    GET: {},
  },
  benchmarks: {
    GET: {},
  },
  'campaign-filters': {
    GET: {},
  },
  'v2-employee-previews': {
    GET: {},
  },
  'v2-employee-migrations': {
    POST: {},
  },
  'v2-group-type-migrations': {
    POST: {},
  },
  'group-types-to-v3-groups': {
    POST: {},
  },
  'campaign-surveys': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'campaign-targets': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'employee-management-items': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'employee-management-items-bulk': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'coaching-session-participants': {
    updating: {},
    PATCH: {},
  },
  'coaching-session-insights': {
    updating: {},
    PATCH: {},
  },
  'focus-areas': {
    updating: {},
    PATCH: {},
  },
  'segmented-feedback-responses-csv': {
    isFetching: false,
  },
  'custom-statement-response-data-csv': {
    isFetching: false,
  },
  'event-types': {
    GET: {},
  },
  'event-schedulings': {
    GET: {},
  },
  'coaching-session-types': {
    GET: {},
  },
  'explicit-permissions': {
    GET: {},
  },
  'internal-people': {
    GET: {},
  },
  'coaching-subscription-types': {
    GET: {},
  },
  'coaching-entitlements': {
    GET: {},
  },
  'coaching-subscriptions': {
    GET: {},
  },
  'coaching-subscription-participants': {
    DELETE: {},
    POST: {},
  },
  'coaching-subscription-stats': { GET: {} },
  'ff-group-types': {
    GET: {},
  },
  'facilitation-teams': {
    GET: {},
    POST: {},
    DELETE: {},
  },
  'lifecycle-campaigns': {
    POST: {},
  },
  'organization-facilitation-teams': {
    GET: {},
    POST: {},
    DELETE: {},
  },
  'organization-facilitators': {
    GET: {},
    POST: {},
    DELETE: {},
  },
  'product-features': {
    GET: {},
    POST: {},
    PATCH: {},
    DELETE: {},
  },
  'transform-users': {
    GET: {},
  },
  'transform-resources': {
    GET: {},
    PATCH: {},
  },
  'query-campaign-themes': {
    POST: {},
  },
  'query-campaign-summaries': {
    POST: {},
  },
  'remove-employees-from-campaign': {
    PATCH: {},
  },
  'segment-filters': {
    GET: {},
  }
}

function switchOrganization(state = initState) {
  const preserveUrls = [
    // As you click back in forth between the organizations table and org details
    // We do not want to re-fetch all orgs every time you return to the table
    '/organizations?include=organization-package-progress',
  ]
  const preserved = pick(state.cache.GET, preserveUrls)

  return {
    ...state,
    cache: {
      GET: { ...preserved },
    },
  }
}

function reduceCampaignLevelGetCalls(state, campaignId, entity, value, action) {
  // Increment Retries if API Call Failed
  const retries =
    action.status === statuses.FAILURE
      ? get(state.cache.GET, [action.path, 'retries'], 0) + 1
      : get(state.cache.GET, [action.path, 'retries'], 0)

  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      isFetchingByCampaign: {
        ...state[entity].isFetchingByCampaign,
        [campaignId]: value,
      },
    },
    cache: {
      ...state.cache,
      GET: {
        ...state.cache.GET,
        [action.path]: {
          status: action.status,
          retries,
          entity,
        },
      },
    },
  }
}

function reduceGetRequest(state = initState, action) {
  const entity = action.entity
  const { campaignId } = action

  if (campaignId) {
    return reduceCampaignLevelGetCalls(state, campaignId, entity, true, action)
  }

  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      isFetching: true,
    },
    cache: {
      ...state.cache,
      GET: {
        ...state.cache.GET,
        [action.path]: {
          retries: get(state.cache.GET, [action.path, 'retries'], 0),
          status: action.status,
          entity,
        },
      },
    },
  }
}

function reduceGetFinish(state = initState, action) {
  const entity = action.entity
  const { campaignId } = action

  if (campaignId) {
    return reduceCampaignLevelGetCalls(
      state,
      campaignId,
      entity,
      undefined,
      action,
    )
  }

  // Increment Retries if API Call Failed
  const retries =
    action.status === statuses.FAILURE
      ? get(state.cache.GET, [action.path, 'retries'], 0) + 1
      : get(state.cache.GET, [action.path, 'retries'], 0)

  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      isFetching: false,
    },
    cache: {
      ...state.cache,
      GET: {
        ...state.cache.GET,
        [action.path]: {
          status: action.status,
          retries,
          entity,
        },
      },
    },
  }
}

function reducePostRequest(state = initState, action) {
  const entity = action.entity
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      isCreating: true,
    },
  }
}

function reducePostFinish(state = initState, action) {
  const entity = action.entity
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      isCreating: false,
    },
  }
}

function reducePatchRequest(state = initState, action) {
  const entity = action.entity
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      updating: {
        ...state[entity].updating,
        [action.id]: true,
      },
    },
  }
}

function reducePatchFinish(state = initState, action) {
  const entity = action.entity

  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      updating: {
        ...state[entity].updating,
        [action.id]: undefined,
      },
    },
  }
}

function reduceDeleteRequest(state = initState, action) {
  const entity = action.entity
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      deleting: {
        ...state[entity].deleting,
        [action.id]: true,
      },
    },
  }
}

function reduceDeleteFinish(state = initState, action) {
  const entity = action.entity

  return {
    ...state,
    [entity]: {
      ...state[entity],
      [action.method]: generateNewParamsRequestState(
        action.method,
        state,
        action,
      ),
      deleting: {
        ...state[entity].deleting,
        [action.id]: undefined,
      },
    },
  }
}

function reduceResetEntityRequests(state = initState, action) {
  const newState = { ...state }
  const { entities } = action

  entities.forEach((e) => {
    const entityInitState = initState[e] || {}
    newState[e] = { ...entityInitState }
  })

  // Busts GET request cache for the specific entities
  const GET = {}
  Object.keys({ ...state.cache.GET }).forEach((key) => {
    const val = state.cache.GET[key]

    if (!includes(entities, val.entity)) {
      GET[key] = val
    }
  })
  newState.cache = {
    GET,
  }

  return newState
}

export default function reducer(state = initState, action) {
  if (!action || !action.type) {
    return state
  }

  if (action.type === SWITCH_ORGANIZATION) {
    return switchOrganization(state)
  }

  if (action.type === CLEAR_ENTITIES) {
    return reduceResetEntityRequests(state, action)
  }

  if (!isApiCall(action.type)) {
    return state
  }

  if (action.method === methods.GET) {
    if (action.status === statuses.REQUEST) {
      return reduceGetRequest(state, action)
    }
    return reduceGetFinish(state, action)
  }

  if (action.method === methods.POST) {
    if (action.status === statuses.REQUEST) {
      return reducePostRequest(state, action)
    }
    return reducePostFinish(state, action)
  }

  if (action.method === methods.PATCH) {
    if (action.status === statuses.REQUEST) {
      return reducePatchRequest(state, action)
    }
    return reducePatchFinish(state, action)
  }

  if (action.method === methods.DELETE) {
    if (action.status === statuses.REQUEST) {
      return reduceDeleteRequest(state, action)
    }
    return reduceDeleteFinish(state, action)
  }

  return state
}
