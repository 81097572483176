/**
 * Enum for Launch Darkly Flag Keys
 * @readonly
 * @enum {string}
 */
export const FlagKeys = {
  DISABLE_MANAGED_ENTITLEMENTS: 'disable-managed-entitlements',
  COACHING_SUBSCRIPTION_DELETION: 'coaching-subscription-deletion',
  MANAGE_SUBSCRIPTIONS_REVAMP: 'manage-subscriptions-revamp',
  MA_VIEW_SUBSCRIPTION: 'ma-view-subscription',
  MA_HYBRID_FORMS_REDIRECT_TO_JOURNEYS: 'ma-hybrid-forms-redirect-to-journeys',
  MA_LIVE_FORMS_REDIRECT_TO_JOURNEYS: 'ma-live-forms-redirect-to-journeys',
  AUTOMATICALLY_COMMIT_IMPORTS: 'automatically-commit-imports',
  OPEN_RESPONSE_THEMES: 'open-response-themes',
  PERCEPTION_SURVEY: 'perception-survey',
  RULE_OF_FIVE_FLEXIBILITY: 'rule-of-5-flexibility',
  SESSION_ATTENDANCE: 'session-attendance',
  OPENAI_FEEDBACK: 'openai-feedback',
  CAMPAIGN_CONFIG_LOCK: 'campaign-config-lock',
  STATEMENT_BREAKDOWN: 'engage-survey-statement-breakdown',
  CAMPAIGN_HIRE_DATE_FILTER: 'engage-campaign-hire-date-filter',
  DOWNLOAD_VIEW_BY_CSV: 'download-view-by-csv',
  ENGAGE_SYNC_DEACTIVATE_TOGGLE: 'engage-sync-deactivate-toggle',
  SHOW_BILLING_CHANGES_PROTIP: 'show-billing-changes-protip',
  FF_INSIGHTS_RESULTS_LINK: 'ff-insights-results-link',
  LIMITED_FEEDBACK_ACCESS: 'limited-feedback-access',
  USE_FIFTEEN5_SEGMENTS: 'use-fifteen-5-segments',
  ENGAGEMENT_PERMISSION_MANAGEMENT: 'engagement-permission-management',
}
