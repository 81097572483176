import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import values from 'lodash/values'
import uniq from 'lodash/uniq'
import { entities } from 'dux/api/action_types'
import {
  ACCESSIBLE_TYPES,
  ORGANIZATIONS_RESULTS_READ_GRANT,
  ORGANIZATIONS_WRITE_GRANT,
  ORGANIZATIONS_ADMINISTRATE_WRITE_GRANT,
} from 'constants/permissions'
import { USER_TYPES } from 'constants/user'
import { ROLES } from 'constants/roles'
import { ACCESS_RESULTS_LEVELS } from 'constants/access_levels'
import { findPermission } from 'utils/emplify/authorization'
import { createEntityGetter, createPropGetter } from 'utils/selectors'

const RESTRICTED_ORG_IDS = process.env.RESTRICTED_ORG_IDS || []

/**
 * Selectors to derive information about the currently logged-in user
 */

function getUserPersonId(state) {
  return state.login.personId
}

function getCurrentOrganizationId(state) {
  return state.organizations.currentOrganizationId
}

function getLatestCampaign(filteredCampaigns) {
  const sortedCampaigns = orderBy(filteredCampaigns, ['startedAt'], ['desc'])

  if (sortedCampaigns && sortedCampaigns.length) {
    return sortedCampaigns[0]
  }

  return null
}

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - The person object for the logged-in user
 */
const selectPerson = createSelector(
  getUserPersonId,
  createEntityGetter(entities.PEOPLE),
  (personId, people) => people[Number(personId)],
)

/**
 * Gets a UI-friendly representation of the user's name
 */
export const selectUsername = createSelector(
  [selectPerson],
  function getUsername(user) {
    if (user && (user.firstName || user.lastName)) {
      // They must have at least a first name or last name
      // Make sure `undefined` does not show up for a missing name
      return `${user.firstName || ''} ${user.lastName || ''}`.trim()
    }
    if (user && user.emailAddress) {
      return user.emailAddress
    }
    return 'Guest'
  },
)

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - The employee object for the logged-in user
 */
const selectEmployee = createSelector(
  getUserPersonId,
  createEntityGetter(entities.EMPLOYEES),
  (personId, employees) => {
    if (!personId) {
      return null
    }
    return find(employees, { personId: Number(personId) })
  },
)

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - The organization id for the logged-in user
 */
const selectOrganizationId = createSelector(selectEmployee, (employee) => {
  if (employee) {
    return employee.organizationId
  }
  return null
})

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - The organization object for the logged-in user
 */
const selectOrganization = createSelector(
  selectOrganizationId,
  createEntityGetter(entities.ORGANIZATIONS),
  (orgId, organizations) => organizations[Number(orgId)],
)

/**
 * Select the user's role for the current organization
 *
 * @param {Object} state - entire Redux state tree
 * @returns {String} - The user's role for the current organization
 */
const selectUserPersonRole = createSelector(
  getCurrentOrganizationId,
  getUserPersonId,
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  (currentOrgId, personId, personRoles, roles) => {
    const hydratedRoles = map(personRoles, (personRole) => {
      if (personRole) {
        const role = get(roles, `${personRole.roleId}.name`)
        return { ...personRole, role }
      }
      return personRole
    })

    // If one of the person's roles is ADMIN for this organization, return the org-specific ADMIN role
    const orgSpecificAdminRole = find(hydratedRoles, {
      role: ROLES.ADMIN,
      organizationId: parseInt(currentOrgId, 10),
      personId: parseInt(personId, 10),
    })
    if (orgSpecificAdminRole) {
      return orgSpecificAdminRole
    }

    // If one of the person's roles is ADMIN with a `null` organization, return ADMIN
    const generalAdminRole = find(hydratedRoles, {
      role: ROLES.ADMIN,
      personId: parseInt(personId, 10),
    })
    if (generalAdminRole) {
      return generalAdminRole
    }

    // Otherwise, return the role for the current organization
    const currentOrgRole = find(hydratedRoles, {
      organizationId: parseInt(currentOrgId, 10),
      personId: parseInt(personId, 10),
    })
    return currentOrgRole || {}
  },
)

const selectUserRole = createSelector(selectUserPersonRole, (userRole) =>
  get(userRole, 'role'),
)

/**
 * Selects ALL person roles for the currently logged in user
 * That are either generic or that apply to the current organization
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - Hydrated Person Roles belonging to the logged-in user
 */
const selectUserRoles = createSelector(
  getCurrentOrganizationId,
  getUserPersonId,
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  (currentOrgId, personId, personRoles, roles) => {
    const hydratedRoles = map(personRoles, (personRole) => {
      if (personRole) {
        const role = get(roles, `${personRole.roleId}.name`)
        return { ...personRole, role }
      }
      return personRole
    })

    return filter(
      hydratedRoles,
      (personRole) =>
        `${personRole.personId}` === `${personId}` &&
        (!personRole.organizationId ||
          `${personRole.organizationId}` === `${currentOrgId}`),
    )
  },
)

/**
 * @param {Object} state - Entire state tree
 * @returns {Object} Permissions for current user, keyed by permission id
 */
const selectUserPermissions = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  getUserPersonId,
  (permissions, personId) => {
    return pickBy(
      permissions,
      (permission) => `${permission.personId}` === `${personId}`,
    )
  },
)

/**
 * Select the current user's permissions for the current organization, keyed by permission id.
 *
 * @param {Object} state - Entire state tree
 * @returns {Object} Permissions, keyed by permission id
 */
const selectUserPermissionsForCurrentOrg = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  getUserPersonId,
  getCurrentOrganizationId,
  (permissions, personId, currentOrgId) => {
    return pickBy(
      permissions,
      (permission) =>
        `${permission.personId}` === `${personId}` &&
        `${permission.organizationId}` === `${currentOrgId}`,
    )
  },
)

/**
 * Select all of the group ids that the current user has a
 * specific 'organizations-groups' permission for.
 * @param {Object} state - Entire state tree
 * @returns {Number[]} Collection of permitted group ids
 */
const selectPermittedGroupIds = createSelector(
  selectUserPermissions,
  (permissions) => {
    const permittedIds = []
    forEach(permissions, (p) => {
      if (p.accessibleType === ACCESSIBLE_TYPES.GROUPS) {
        permittedIds.push(p.accessibleId)
      }
    })
    return uniq(permittedIds)
  },
)

/**
 * Select all of the group ids that the current user has a
 * specific 'organizations-groups' permission for.
 * @param {Object} state - Entire state tree
 * @returns {Number[]} Collection of permitted group ids
 */
const selectPermittedV3GroupIds = createSelector(
  selectUserPermissions,
  (permissions) => {
    const permittedIds = []
    forEach(permissions, (p) => {
      if (p.accessibleType === ACCESSIBLE_TYPES.V3_GROUPS) {
        permittedIds.push(p.accessibleId)
      }
    })
    return uniq(permittedIds)
  },
)

/**
 * Select all of the campaign report ids that the current user
 * has a specific 'organizations-campaign' permission for.
 * @param {Object} state - Entire state tree
 * @returns {Number[]} Collection of permitted campaign report ids
 */
const selectPermittedCampaignReportIds = createSelector(
  selectUserPermissions,
  (permissions) => {
    const permittedIds = []
    forEach(permissions, (p) => {
      if (p.accessibleType === ACCESSIBLE_TYPES.CAMPAIGNS) {
        permittedIds.push(p.accessibleId)
      }
    })
    return permittedIds
  },
)

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user is an admin.
 */
const selectIsAdmin = createSelector(
  selectUserPersonRole,
  getCurrentOrganizationId,
  (userPersonRole, organizationId) => {
    const userRole = get(userPersonRole, 'role')

    const isAdmin = userRole === ROLES.ADMIN
    const orgIsRestricted = includes(RESTRICTED_ORG_IDS, `${organizationId}`)
    const isAdminForRestrictedOrg =
      isAdmin &&
      orgIsRestricted &&
      `${userPersonRole.organizationId}` === `${organizationId}`

    return isAdmin && (!orgIsRestricted || isAdminForRestrictedOrg)
  },
)

const selectIsEngagementAdmin = createSelector(
  selectUserRole,
  (userRole) => userRole === ROLES.ENGAGEMENT_ADMIN,
)

export const selectIsOrganizationAdmin = createSelector(
  selectUserRole,
  (userRole) => userRole === ROLES.ORGANIZATION_ADMIN,
)

const selectIsInternalUser = createSelector(selectUserRole, (userRole) => {
  return includes(
    [ROLES.ADMIN, ROLES.CUSTOMER_SUCCESS_MANAGER, ROLES.EXECUTIVE_ADVISOR],
    userRole,
  )
})

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user is an admin.
 */
const selectIsPermittedUser = createSelector(
  selectUserRole,
  createPropGetter('bypassRoles'),
  (userRole, bypassRoles) =>
    userRole === ROLES.ADMIN || includes(bypassRoles, userRole),
)

/**
 * Select if the current user is a read-only user.
 * This means the user only has 1 permission for the current organization,
 * and that permission is an organizations read permission.
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user is a partner.
 */
const selectIsOrgReadOnlyUser = createSelector(
  selectUserPermissionsForCurrentOrg,
  getCurrentOrganizationId,
  (permissions, currentOrgId) => {
    const permissionsArray = values(permissions)
    return (
      permissionsArray.length === 1 &&
      findPermission(permissionsArray, ORGANIZATIONS_RESULTS_READ_GRANT, {
        organizationId: currentOrgId,
      })
    )
  },
)

/**
 * Select whether or not the current user has an 'organizations' read permission.
 * Will also return true is the user is an admin.
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user has the permission
 */
const selectHasOrganizationResultsReadPermission = createSelector(
  selectIsAdmin,
  selectUserPermissions,
  getCurrentOrganizationId,
  (isAdmin, permissions, currentOrgId) => {
    return (
      isAdmin ||
      findPermission(values(permissions), ORGANIZATIONS_RESULTS_READ_GRANT, {
        organizationId: currentOrgId,
      })
    )
  },
)

/**
 * Select whether or not the current user has an 'organizations' write permission.
 * Will also return true is the user is an admin.
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user has the permission
 */
const selectHasOrganizationWritePermission = createSelector(
  selectIsAdmin,
  selectUserPermissions,
  getCurrentOrganizationId,
  (isAdmin, permissions, currentOrgId) => {
    return (
      isAdmin ||
      findPermission(values(permissions), ORGANIZATIONS_WRITE_GRANT, {
        organizationId: currentOrgId,
      })
    )
  },
)

const selectHasOrganizationAdministrateWritePermission = createSelector(
  selectIsAdmin,
  selectUserPermissions,
  getCurrentOrganizationId,
  (isAdmin, permissions, currentOrgId) => {
    return (
      isAdmin ||
      findPermission(
        values(permissions),
        ORGANIZATIONS_ADMINISTRATE_WRITE_GRANT,
        {
          organizationId: currentOrgId,
        },
      )
    )
  },
)

/**
 * Select whether or not the current user has ANY permission to a provided grant
 * @param {Object} state - entire Redux state tree
 * @returns {Boolean} - True if the user has the permission
 */
const selectHasOrganizationAnyPermission = createSelector(
  selectIsAdmin,
  selectUserPermissions,
  getCurrentOrganizationId,
  createPropGetter('requiredGrant'),
  (isAdmin, permissions, organizationId, requiredGrant) => {
    if (isAdmin) {
      return true
    }
    if (requiredGrant.length) {
      return !!find(requiredGrant, (rg) =>
        findPermission(values(permissions), rg, {
          organizationId,
        }),
      )
    }
    return !!findPermission(values(permissions), requiredGrant, {
      organizationId,
    })
  },
)

const selectUserType = createSelector(
  selectHasOrganizationResultsReadPermission,
  selectIsOrgReadOnlyUser,
  selectPermittedV3GroupIds,
  selectUserRole,
  function getUserType(
    hasOrgResultsReadPermission,
    hasOrgReadOnlyPermission,
    permittedGroups,
    userRole,
  ) {
    if (userRole === ROLES.ADMIN) {
      return USER_TYPES.ADMIN
    }

    if (userRole === ROLES.ENGAGEMENT_ADMIN) {
      return USER_TYPES.ENGAGEMENT_ADMIN
    }

    if (userRole === ROLES.ORGANIZATION_ADMIN) {
      return USER_TYPES.ORGANIZATION_ADMIN
    }

    if (userRole === ROLES.COACH) {
      return USER_TYPES.COACH
    }

    if (userRole === ROLES.FACILITATOR) {
      return USER_TYPES.FACILITATOR
    }

    if (userRole === ROLES.EXECUTIVE_ADVISOR) {
      return USER_TYPES.EXECUTIVE_ADVISOR
    }

    if (hasOrgReadOnlyPermission) {
      return USER_TYPES.READ_ONLY
    }

    if (userRole === ROLES.LEADER && hasOrgResultsReadPermission) {
      return USER_TYPES.ORGANIZATION_LEADER
    }

    if (
      userRole === ROLES.LEADER &&
      permittedGroups &&
      permittedGroups.length === 1
    ) {
      return USER_TYPES.SINGLE_GROUP_LEADER
    }

    if (
      userRole === ROLES.LEADER &&
      permittedGroups &&
      permittedGroups.length > 1
    ) {
      return USER_TYPES.MULTI_GROUP_LEADER
    }

    return USER_TYPES.EMPLOYEE
  },
)

/**
 * Determine what the user's results access is.
 * Currently, used only for analytics. Could be used elsewhere if needed.
 * @param {Object} state - The entire redux state tree
 * @return {String} level of results access from constant
 */
const selectResultAccessLevel = createSelector(
  selectHasOrganizationResultsReadPermission,
  selectPermittedV3GroupIds,
  (hasOrgAccess, permittedGroupIds) => {
    if (hasOrgAccess) {
      return ACCESS_RESULTS_LEVELS.FULL
    }
    if (permittedGroupIds.length) {
      return ACCESS_RESULTS_LEVELS.LIMITED
    }
    return ACCESS_RESULTS_LEVELS.NONE
  },
)

const selectHasLimitedFeedbackAccess = createSelector(
  [selectOrganization],
  (organization) => {
    const openTextFeedbackAccess = get(
      organization,
      'openTextFeedbackAccess',
      null,
    )
    return openTextFeedbackAccess === 'admins_only'
  },
)

export {
  getUserPersonId,
  selectEmployee,
  selectHasOrganizationAdministrateWritePermission,
  selectHasOrganizationResultsReadPermission,
  selectHasOrganizationWritePermission,
  selectHasOrganizationAnyPermission,
  selectIsAdmin,
  selectIsEngagementAdmin,
  selectIsPermittedUser,
  selectIsInternalUser,
  selectOrganization,
  selectPermittedCampaignReportIds,
  selectPermittedGroupIds,
  selectPermittedV3GroupIds,
  selectPerson,
  selectResultAccessLevel,
  selectUserPermissions,
  selectUserPersonRole,
  selectUserRole,
  selectUserRoles,
  selectUserType,
  selectHasLimitedFeedbackAccess,
}
