const CAMPAIGN_TARGETABLE_TYPES = {
  V3_GROUPS: 'v3_groups',
  EMPLOYEES: 'employees',
  GENERATION_GROUPS: 'generation_groups',
  TENURE_GROUPS: 'tenure_groups',
  HIRE_COHORT_GROUPS: 'hire_cohort_groups',
}

const CAMPAIGN_TARGET_CLUSIVITIES = {
  EXCLUDED: 'excluded',
  INCLUDED: 'included',
}

const LEGACY_CAMPAIGN_TARGETABLE_GROUPINGS = [
  CAMPAIGN_TARGETABLE_TYPES.V3_GROUPS,
  CAMPAIGN_TARGETABLE_TYPES.GENERATION_GROUPS,
  CAMPAIGN_TARGETABLE_TYPES.TENURE_GROUPS,
  CAMPAIGN_TARGETABLE_TYPES.HIRE_COHORT_GROUPS,
]

export {
  CAMPAIGN_TARGETABLE_TYPES,
  CAMPAIGN_TARGET_CLUSIVITIES,
  LEGACY_CAMPAIGN_TARGETABLE_GROUPINGS,
}
